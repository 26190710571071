// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/root.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/root.tsx");
}
// REMIX HMR END

import "@openfonts/source-sans-pro_all/index.css?__remix_sideEffect__";
import { cssBundleHref } from "@remix-run/css-bundle";
import { json } from "@remix-run/node";
import { Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData } from "@remix-run/react";
import React from "react";
import stylesheet from "~/tailwind.css";
export const links = () => [{
  rel: "stylesheet",
  href: stylesheet
}, {
  rel: "preconnect",
  href: "https://fonts.googleapis.com"
}, {
  rel: "preconnect",
  href: "https://fonts.gstatic.com",
  crossOrigin: "anonymous"
}, {
  rel: "preconnect",
  href: "https://use.typekit.net",
  crossOrigin: "anonymous"
}, {
  rel: "stylesheet",
  href: "https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,400;0,600;0,700;0,900;1,200;1,400;1,600;1,700;1,900&amp;display=swap"
}, {
  rel: "preload",
  as: "style",
  href: "https://use.typekit.net/ysb3beq.css"
}, {
  href: "https://use.typekit.net/ysb3beq.css",
  rel: "stylesheet"
}, ...(cssBundleHref ? [{
  rel: "stylesheet",
  href: cssBundleHref
}] : [])];
export const loader = async () => {
  return json({
    ENV: {
      SHIRTSPACE_API_BASE_URL: process.env.SHIRTSPACE_API_BASE_URL
    }
  });
};

// https://remix.run/docs/en/main/guides/envvars#browser-environment-variables

const App = () => {
  _s();
  const {
    ENV
  } = useLoaderData();
  return <html lang="en" className="h-full">

      <head>
        <meta charSet="utf-8" />
        <title>Blank T Shirts & Wholesale Apparel | ShirtSpace</title>
        <meta name="viewport" content="width=device-width,initial-scale=1" />

        <script src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async />

        <Meta />
        <Links />
      </head>
      <body>
        <Outlet />
        <ScrollRestoration />
        <script dangerouslySetInnerHTML={{
        __html: `window.ENV = ${JSON.stringify(ENV)}`
      }} />

        <Scripts />
        <LiveReload />
      </body>
    </html>;
};
_s(App, "GgIwV96Z9ISaOFpQYlmr04u341M=", false, function () {
  return [useLoaderData];
});
_c = App;
export default App;
var _c;
$RefreshReg$(_c, "App");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;